import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { HeaderUserDropdownType } from 'types/app';

export const useHomeNavigation = (): {
  homeNavigationList: HeaderUserDropdownType[];
} => {
  const { t } = useTranslation('home-navigation');

  // todo uncomment and add links when we need this links in HomeLayout
  const defaultHomeNavigationList = [
    // { label: 'product', href: '#' },
    { label: 'pricing', href: '/pricing' },
    // { label: 'FAQ', href: '/faq' },
  ];

  const homeNavigationList = useMemo(
    () =>
      defaultHomeNavigationList.map((item) => ({
        ...item,
        label: t(item.label),
      })),
    [t]
  );
  return { homeNavigationList };
};
